import WebServiceRequest from '../Api/WebServiceRequest'

class WalletGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Wallet/GetAll')
    }
    setParams(data){
      super.setRequestParam(data)
    }
}
class WalletGetByUserIdRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setUserId(id){
        super.setTag('Wallet/GetByUserId?userId='+id)
    }
}
class WalletUpdateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Wallet/updateWallet')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class GetWalletTransactions extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Wallet/GetTransactions')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class Withdraw extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Wallet/Withdraw')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class Deposite extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Wallet/Deposite')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
export {
    Withdraw,
    Deposite,
    WalletGetAllRequest,
    WalletGetByUserIdRequest,
    WalletUpdateRequest,
    GetWalletTransactions
};

<template>
  <b-overlay
      :show="showOverlay"
      rounded="sm"
  >
    <div v-if="wallet !== null">
      <b-card>

        <b-form>
          <b-row>

            <b-col
                cols="12"
                class="mb-1"
            >
              <h3>افزایش موجودی کیف پول</h3>
            </b-col>

            <!-- Field: credit -->
            <b-col
                cols="12"
                md="4"
            >
              <b-form-group
                  label-for="credit"
                  label="مقدار موجودی فعلی (تومان)"
              >
                <b-form-input
                    id="credit"
                    disabled
                    v-model="makeItPretty"
                />
              </b-form-group>
            </b-col>


          </b-row>
          <b-row>

            <!-- Field: increaseCredit -->
            <b-col
                cols="12"
                md="4"
                class="mt-2"
            >
              <b-form-group
                  label-for="increaseCredit"
                  label="مقدار افزایش موجودی"
              >

                <b-form-input
                    id="increaseCredit"
                    @focus="newWalletData.credit=null"
                    v-model="formattedAmount"
                />
              </b-form-group>
              <b-form-group
                  label-for="increaseCredit"
                  label="علت"
              >
                <textarea class="form-control" v-model="newWalletData.description"></textarea>
              </b-form-group>
              <!-- Action Buttons -->

            </b-col>
            <b-col v-if="newWalletData.credit!==0" cols="12">
              <b-button
                  variant="success"
                  class="mt-1  mb-sm-0 mr-0 mr-sm-1"
                  v-b-modal.modal-confirm
                  @click="setTransactionType(1)"
              >
                افزایش موجودی
              </b-button>
              <b-button
                  variant="danger"
                  class="mt-1  mb-sm-0 mr-1 ml-2 mr-sm-1"
                  v-b-modal.modal-confirm
                  @click="setTransactionType(2)"
              >
                کاهش موجودی
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="12">
              <b-row>
                <b-col cols="12" md="3">
                  <clear-sorts-btn @clear="sortBy = ''"></clear-sorts-btn>
                </b-col>
                <b-col cols="12">
                  <b-table
                      ref="refUserListTable"
                      class="position-relative"
                      :items="transactions"
                      responsive
                      :fields="myTableColumns"
                      primary-key="id"
                      :sort-by.sync="sortBy"
                      show-empty
                      bordered
                      striped
                      empty-text="موردی موجود نیست!"
                  >
                    <template #cell(amount)="data">
                      <b-badge
                          pill
                          class="text-capitalize"
                          :variant="``"
                      >
                        {{ Intl.NumberFormat('fa-IR').format(data.item.amount) }}
                        <small>تومان</small>
                      </b-badge>
                    </template>
                    <template #cell(createDate)="data">
                      <small>
                        {{ new Date(data.item.createDate).toLocaleTimeString('fa-IR') }} -
                        {{ new Date(data.item.createDate).toLocaleDateString('fa-IR') }}
                      </small>
                    </template>
                    <template #cell(operation)="data">
                      <b-badge
                          v-if="data.item.operation===1"
                          pill
                          class="text-capitalize"
                          variant="success"
                      >
                        <small>واریز</small>
                      </b-badge>
                      <b-badge
                          v-if="data.item.operation===-1"
                          pill
                          class="text-capitalize"
                          variant="danger"
                      >
                        <small>برداشت</small>
                      </b-badge>
                    </template>


                  </b-table>

                </b-col>
              </b-row>


            </b-col>
            <b-col v-if="totalCounts>0" cols="12">
              <b-pagination
                  v-model="currentPage"
                  :total-rows="totalCounts"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon style="transform: rotate(180deg)"
                                icon="ChevronLeftIcon"
                                size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
      <b-modal
          id="modal-confirm"
          centered
          ok-title="تایید"
          cancel-title="بستن"
          @ok="confirmTransaction"
      >
        <div class="w-100">
          <span>{{ transactionType === 1 ? 'افزایش ' : 'کاهش ' }} موجودی به مبلغ {{ newWalletData.credit }} تومان</span>
        </div>
      </b-modal>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup, BForm, BModal, BOverlay
} from 'bootstrap-vue'
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {Deposite, GetWalletTransactions, WalletGetByUserIdRequest, Withdraw} from "@/libs/Api/Wallet";
import {WalletUpdateRequest} from "@/libs/Api/Wallet";
import helper from "@/libs/Helper";
import ClearSortsBtn from "@/views/components/clearSortsBtn.vue";

export default {
  name: "WalletInfo",
  title: "ویرایش کیف پول - پنل ادمین انزا مد",
  data() {
    return {
      wallet: null,
      showOverlay: false,
      userId: this.$route.params.id,
      currentPage: 1,
      sortBy: '',
      perPage: 10,
      totalCounts: null,
      myTableColumns: [
        {key: 'amount', label: 'مقدار', sortable: true},
        {key: 'createDate', label: 'تاریخ', sortable: true},
        {key: 'description', label: 'علت', sortable: true},
        {key: 'operation', label: 'نوع تراکنش', sortable: true},
      ],
      transactions: null,
      selectedPageId: 1,
      transactionType: 1,
      newWalletData: {
        userId: this.$route.params.id,
        credit: 0,
        description: ''
      }
    }
  },
  async created() {
    await this.getWallet();
    await this.getWalletTransactions()
  },
  computed: {
    makeItPretty: {
      get() {
        return Intl.NumberFormat('en-US').format(this.wallet.credit)
      },
      set() {
        return Intl.NumberFormat('en-US').format(this.wallet.credit)
      }
    },
    formattedAmount: {

      get: function () {
        return this.newWalletData.credit
      },
      set: function (newValue) {

        // This setter is getting number, replace all commas with empty str
        // Then start to separate numbers with ',' from beginning to prevent
        // from data corruption
        if (newValue) {
          this.newWalletData.credit = helper.convertNumbers2English(newValue)
              .toString()
              .replaceAll(",", "")
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          // Remove all characters that are NOT number
          this.newWalletData.credit = this.newWalletData.credit.replace(
              /[a-zA-Z+*!@#$%^&*()_;:'"|<>/?{}\u0600-\u06EC/\-/\.]/g, ""
          );
        } else if (!newValue || newValue == "") {
          this.newWalletData.credit = null;
        }

      },
    },
  },
  methods: {
    setTransactionType(type) {
      this.transactionType = type
    },
    async confirmTransaction() {
      if (this.transactionType === 1) {
        this.deposite()
      } else {
        this.withdraw()
      }
    },
    async getWalletTransactions() {
      let _this = this;
      _this.showOverlay = true;
      let getWalletTransactions = new GetWalletTransactions(_this);
      let data = {
        userId: _this.userId,
        page: _this.selectedPageId,
        size: 10
      }
      getWalletTransactions.setParams(data);
      await getWalletTransactions.fetch(function (content) {
        _this.transactions = content.data.data.reverse()
        _this.totalCounts = content.data.totalPage
        _this.showOverlay = false;
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    async getWallet() {
      let _this = this;
      _this.showOverlay = true;

      let walletGetByUserIdRequest = new WalletGetByUserIdRequest(_this);
      walletGetByUserIdRequest.setUserId(_this.userId);
      await walletGetByUserIdRequest.fetch(function (content) {
        _this.wallet = content[0]
        _this.showOverlay = false;
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    async withdraw() {
      let _this = this;
      _this.newWalletData.credit = _this.newWalletData.credit.replaceAll(',', '')
      _this.showOverlay = true;
      let walletGetByUserIdRequest = new Withdraw(_this);
      walletGetByUserIdRequest.setRequestParam(_this.newWalletData);
      await walletGetByUserIdRequest.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات با موفقیت انجام شد`,
          },
        })
        _this.getWallet()
        _this.getWalletTransactions()
        _this.newWalletData = {
          userId: this.$route.params.id,
          credit: 0,
          description: ''
        }
        _this.showOverlay = false;
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    async deposite() {
      let _this = this;
      _this.showOverlay = true;
      _this.newWalletData.credit = _this.newWalletData.credit.replaceAll(',', '')

      let deposite = new Deposite(_this);
      deposite.setRequestParam(_this.newWalletData);
      await deposite.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات با موفقیت انجام شد`,
          },
        })
        _this.getWallet()
        _this.getWalletTransactions()
        _this.newWalletData = {
          userId: this.$route.params.id,
          credit: 0,
          description: ''
        }
        _this.showOverlay = false;
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    }
  },
  components: {
    ClearSortsBtn,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BFormGroup,
    BBadge,
    BForm,
    BDropdown,
    BDropdownItem,
    BPagination,
    BModal,
    BOverlay,
    vSelect,
  }
}
</script>

<style scoped>

</style>
